<template>
  <div>
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>説明会管理</span>
      </div>

      <div v-show="type == 1">

        <div class="tool-bar">
        <span @click="goAdd()" class="add-button">＋&nbsp;&nbsp;新規作成</span>
        <el-select v-model="status" @change="changeSelect"  placeholder="状態" class="status-col">
          <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        </div>

        <div  class="table-box">
          <div class="table-content">
            <div class="table-c">
              <el-table
                  :data="tableData"
                  style="width: 100%">

                <el-table-column
                    prop="compName"
                    label="企業名"
                    min-width="68%"
                >
                  <template slot-scope="scope">
                    <div>{{scope.row.compName}}</div>
                  </template>
                </el-table-column>

                <el-table-column
                    prop="sort"
                    min-width="20%"
                    label="表示順">
                </el-table-column>

                <el-table-column label="開催日時"  min-width="40%">
                  <template slot-scope="scope">
                    {{scope.row.startDate.substring(0, 16)}}
                  </template>
                </el-table-column>



                <el-table-column
                    prop="seminarTitle"
                    show-overflow-tooltip
                    label="説明会タイトル">
                </el-table-column>

                <el-table-column
                    prop="entryCnt"
                    min-width="32%"
                    label="エントリー数">
                  <template slot-scope="scope">
                    {{scope.row.entryCnt}}名
                  </template>
                </el-table-column>

                <el-table-column
                    min-width="20%"
                    label="状態">
                  <template slot-scope="scope">
                    <p style="color: #3CB55E;" v-if="scope.row.status == 1">有効</p>
                    <p v-else style="color: #A3A3A3;">無効</p>
                  </template>
                </el-table-column>
                <el-table-column label="" min-width="104%">
                  <template slot-scope="scope">
                    <template>

                      <div class="buttons">

                        <div @click="changeStatus(0, scope.row, scope.$index)" class="status-btn-wu" v-if="scope.row.status == 1">無効</div>
                        <div @click="changeStatus(1, scope.row, scope.$index)" v-else class="status-btn-you">有効</div>
                        <div class="button edit" @click="handleEdit(scope.$index, scope.row)">
                          <img src="../../assets/images/edit.png"/>
                          編集
                        </div>
                        <div class="button edit" @click="handleCopy(scope.row)">
                          <img src="../../assets/images/copy.png"/>
                          コピー
                        </div>
                        <div class="button delete" @click="handleDelete(scope.$index, scope.row)">
                          <img src="../../assets/images/delete.png"/>
                          削除
                        </div>

                      </div>
                    </template>
<!--                    <template v-else>-->
<!--                      <div class="buttons">-->
<!--                        <div class="button detail" @click="handleDetail(scope.row)">詳細</div>-->
<!--                      </div>-->

<!--                    </template>-->
                  </template>
                </el-table-column>
              </el-table>



            </div>

          </div>



        </div>

        <div class="page-col">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :page-size="20"
              :total="total">
          </el-pagination>
        </div>


        <div class="button-bottom" @click="goUrl()">説明会エントリー管理へ</div>
      </div>

      <explain-meeting-detail @changeType="changeType" ref="meetingDetail" v-show="type == 3"></explain-meeting-detail>

    </div>
  </div>
</template>

<script>
import explainMeetingDetail from '@/components/pageComponents/explainMeetingDetail.vue'
export default {
  name: "explainMeeting",

  components: {
    explainMeetingDetail
  },

  data() {
    return {
      type: 1,
      total:0,
      status:'',
      statusList:[
        {
          label:"",value:""
        },
        {
          label:"有効",value:"1"
        },
        {
          label:"無効",value:"0"
        }
      ],
      pageIndex:1,
      tableData: [
      ]
    }
  },
  created() {
    this.seminarList();
  },
  methods: {
    goUrl(){
      this.$router.push('entryLevel?detailContent=説明会エントリー');
    },
    handleCopy(row){
      this.$router.push("explainMeetingAdd?cid="+row.id);
    },
    goAdd(){
      this.$router.push("explainMeetingAdd")
    },
    handleDetail(row){
      this.changeType(3);
      this.$refs['meetingDetail'].initData(row);
    },
    handleCurrentChange(e){
      console.log(e);
      this.pageIndex=e;
      this.seminarList();
    },
    changeSelect(){
      this.pageIndex=1;
      this.seminarList();
    },
    async seminarList() {
      let res = await this.$axios({url: `/seminar/list`, data:
            {pageIndex: this.pageIndex,pageSize:20,token:localStorage.getItem("RPA_TOKEN"),status:this.status?this.status:-1}
        , method: "post"});
      if(res.code == '000') {

        this.tableData=res.data.records;
        this.total=res.data.total;
        console.log(this.total);
      }
    },

    async changeStatus(status, row, index) {
      let url = '/seminar/valid';
      if(status == 0) {
        url = '/seminar/invalid';
      }

      let res = await this.$axios({url: url, data:{id: row.id}, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });

        this.tableData[index].status = status;
      }

    },

    handleEdit(index, row) {
      console.log(index, row);

      this.$router.push("explainMeetingAdd?id="+row.id);

    },
    handleDelete(index, row){
      let that=this;

      this.$confirm('説明会を削除してよろしいですか？', '', {
        confirmButtonText: 'はい',
        cancelButtonText: 'キャンセル',
        center: true
      }).then(() => {
        that.handleDeleteHandel(index, row);
      }).catch(() => {

      });
    },
    async handleDeleteHandel(index, row) {
      console.log(index, row);
      let USER_INFO=JSON.parse(localStorage.getItem("USER_INFO"));
      let res = await this.$axios({url: `/seminar/delete`, data:
            {
              operatorId: parseInt(USER_INFO.id),
              id:row.id
            }
        , method: "post"});
      if(res.code == '000') {
        this.$message(res.message);
        this.seminarList();
      }

    },


    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    changeType(type) {
      this.type = type;
      if(type==1){



        this.seminarList();


      }
    }
  }
}
</script>

<style scoped src="../../style/explainMeeting.css">

</style>
